import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonRadio,
  IonRadioGroup,
  IonRange,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { pick } from "lodash-es";
import { ErrorMessage } from "@hookform/error-message";
import { closeOutline } from "ionicons/icons";

import AppLayout from "../layouts/AppLayout";
import AppHeader from "../components/AppHeader";
import useError from "../hooks/useError";
import useAuthStore from "../stores/useAuthStore";
import useCategories from "../hooks/useCategories";
import useExperienceLevels from "../hooks/useExperienceLevels";
import { Category, ExperienceLevel } from "../interfaces/Interfaces";
import { useLocale } from "../contexts/LocaleContext";
import {
  CreateCityInput,
  CreateTourByGptInput,
  useCreateTourByGptMutation,
} from "../graphql/backend/__generated__/backend-graphql-sdk.generated";
import "./CreateTourPage.scss";
import useRoutes from "../hooks/useRoutes";
import useToast from "../hooks/useToast";
import { useCity } from "../contexts/CityContext";
import { useTourCreationRequest } from "../contexts/TourCreationRequestContext";
import useIonVisible from "../hooks/useIonVisible";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";
import starsIcon from "../assets/tour-creation/stars.svg";

const RadioGroupSection: React.FC<{
  options: (ExperienceLevel | Category)[];
  control: any;
  fieldName: string;
  errors: any;
  rules?: any;
}> = ({ options, control, fieldName, errors, rules }) => {
  return (
    <>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <p className="ml-4 mt-[2px] text-left text-[0.7rem] text-red-500">
            {message}
          </p>
        )}
      />
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <IonRadioGroup className="pb-2" value={value} onIonChange={onChange}>
            {options?.map((option) => (
              <IonItem key={option.id} lines="none">
                <IonRadio
                  slot="end"
                  mode="md"
                  color="primary"
                  value={option.id}
                />
                <div>
                  <div className="flex items-center">
                    {"icon" in option && !!option?.icon?.url && (
                      <IonIcon
                        src={option?.icon?.url}
                        className="mr-[10px] h-[20px] w-[20px] shrink-0"
                      />
                    )}
                    <span className="font-medium text-[#232437]">
                      {option.title}
                    </span>
                  </div>
                  {"description" in option && (
                    <div className="mb-6 text-[0.875rem] text-[#687582]">
                      {option?.description}
                    </div>
                  )}
                </div>
              </IonItem>
            ))}
          </IonRadioGroup>
        )}
      />
    </>
  );
};

const DurationSection: React.FC<{
  register: any;
  fieldName: string;
}> = ({ register, fieldName }) => {
  const { t } = useTranslation();

  return (
    <>
      <IonRange
        {...register(fieldName)}
        color="primary"
        pin={true}
        pinFormatter={(value: number) =>
          `${value / 60} ${t("units.time.hours.fullForm")}`
        }
        min={30}
        max={300}
        step={30}
      />
      <div className="absolute bottom-0 left-0 right-0 flex justify-between text-[0.75rem] text-[#687582]">
        <span>30 {t("units.time.minutes.shortForm")}</span>
        <span>5 {t("units.time.hours.fullForm")}</span>
      </div>
    </>
  );
};

const CreateTourPage: React.FC = () => {
  const { t } = useTranslation();
  const { handleBackendError } = useError();
  const { isVisible } = useIonVisible();
  const { categoriesInQueryLocale } = useCategories(isVisible, true);
  const { experienceLevelsInQueryLocale } = useExperienceLevels(isVisible);
  const { locale } = useLocale();
  const router = useIonRouter();
  const { currentPath, loginPath } = useRoutes();
  const { presentToast } = useToast();
  const { currentCity } = useCity();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const {
    tourCreationRequestStatisticsByUser,
    numberOfAvailableTourCreationRequests,
    setTourCreationRequestStatisticsByUser,
  } = useTourCreationRequest();

  const [createTourByGptMutation] = useCreateTourByGptMutation();

  const {
    register,
    reset,
    control,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<CreateTourByGptInput>();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR_CREATION);
    }
  });

  useEffect(
    () => {
      resetForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, currentCity]
  );

  const resetForm = async () => {
    let cityData = {};

    if (currentCity) {
      cityData = currentCity.id
        ? { datoCityId: currentCity.id }
        : {
            cityData: pick(currentCity, [
              "name",
              "location",
            ]) as CreateCityInput,
          };
    }

    await reset({
      ...cityData,
      tourDuration: 30,
      datoExperienceLevelId: "",
      datoMainCategoryId: "",
    });

    // avoid displaying errors after resetting form
    clearErrors(["datoExperienceLevelId", "datoMainCategoryId"]);
  };

  const createTour = async (input: CreateTourByGptInput) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.CREATE_TOUR, {
        input,
        currentCityId: currentCity?.id,
        currentCityName: currentCity?.name,
      });
    }

    if (!input.cityData && !input.datoCityId) {
      presentToast("createTour.form.cityData.validators.required", "danger");
      return;
    }

    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    resetForm();

    await handleBackendError(async () => {
      const { errors } = await createTourByGptMutation({
        variables: { input },
      });
      if (errors) return errors;

      setTourCreationRequestStatisticsByUser({
        ...tourCreationRequestStatisticsByUser,
        inProgress: tourCreationRequestStatisticsByUser?.inProgress + 1,
      });

      presentToast(
        "createTour.successMessageForTourCreationRequest",
        "secondary",
        20000,
        "top",
        [
          {
            icon: closeOutline,
            role: "cancel",
          },
        ]
      );
    });
  };

  return (
    <AppLayout>
      <AppHeader />

      <IonContent>
        <div className="relative mx-auto min-h-full max-w-xl bg-[#FAFAFA] px-2">
          <form
            onSubmit={handleSubmit(createTour)}
            className="mb-10 flex flex-col"
          >
            <div className="border-b border-[rgba(217,217,217,0.2)]">
              <div className="py-5 pl-4">
                <h2 className="text-[1.25rem] font-semibold text-[#232437]">
                  1. {t("createTour.form.experienceLevel.title")}
                </h2>
              </div>
              <RadioGroupSection
                options={experienceLevelsInQueryLocale[locale]}
                control={control}
                fieldName="datoExperienceLevelId"
                errors={errors}
                rules={{
                  required: t(
                    "createTour.form.experienceLevel.validators.required"
                  )!,
                }}
              />
            </div>

            <div className="border-b border-[rgba(217,217,217,0.2)]">
              <div className="py-5 pl-4">
                <h2 className="text-[1.25rem] font-semibold text-[#232437]">
                  2. {t("createTour.form.mainCategory.title")}
                </h2>
                <div className="text-[0.875em] text-[#687582]">
                  {t("createTour.form.mainCategory.text")}
                </div>
              </div>
              <RadioGroupSection
                options={categoriesInQueryLocale[locale]}
                control={control}
                fieldName="datoMainCategoryId"
                errors={errors}
                rules={{
                  required: t(
                    "createTour.form.mainCategory.validators.required"
                  )!,
                }}
              />
            </div>

            <div>
              <div className="pl-4 pt-5">
                <h2 className="text-[1.25rem] font-semibold text-[#232437]">
                  3. {t("createTour.form.duration.title")}
                </h2>
                <div className="text-[0.875rem] text-[#687582]">
                  {t("createTour.form.duration.text")}
                </div>
              </div>
              <div className="relative mx-7 mb-10">
                <DurationSection register={register} fieldName="tourDuration" />
              </div>
            </div>

            {!!numberOfAvailableTourCreationRequests && (
              <div className="mb-3 flex items-center justify-center text-[0.875rem] text-[#848D96]">
                <div className="mr-2 h-[6px] w-[6px] rounded-full bg-[#E59953]" />
                <span
                  className="text-[#535E69]"
                  dangerouslySetInnerHTML={{
                    __html: t("createTour.statistics.message", {
                      limit: numberOfAvailableTourCreationRequests?.limit,
                      remainder:
                        numberOfAvailableTourCreationRequests?.remainder,
                    })!,
                  }}
                ></span>
              </div>
            )}

            <IonButton
              type="submit"
              shape="round"
              className="h-[45px] font-semibold normal-case"
              style={{
                "--background": "linear-gradient(to right, #E16A4F, #E14F84)",
              }}
            >
              <IonIcon src={starsIcon} className="mr-1" />
              {t("createTour.form.buttons.create")}
            </IonButton>
          </form>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CreateTourPage;
