import { IonButton } from "@ionic/react";
import clsx from "clsx";

type IonButtonProps = Omit<
  React.ComponentProps<typeof IonButton>,
  "size" | "style"
>;

const AppButton: React.FC<IonButtonProps> = ({ children, ...props }) => {
  return (
    <IonButton
      {...props}
      size="large"
      className={clsx(
        "w-full text-[16px] font-semibold normal-case",
        props.className
      )}
      fill={props.fill}
      style={{
        ...(props.fill === "outline" ? { "--background": "#ffffff" } : {}),
        "--padding-top": "20px",
        "--padding-bottom": "20px",
      }}
    >
      {children}
    </IonButton>
  );
};

export default AppButton;
